import { useSelector } from 'react-redux'
import '@sendbird/uikit-react/dist/index.css'
import { SENDBIRD_INFO } from '../../../utils/helpers/constant'
import ChatUI from './chatui'
import { SendBirdProvider } from '@sendbird/uikit-react'
import { CustomDropZone } from './customDropZone'
import { TypingIndicatorType } from '@sendbird/uikit-react';

const Chat = ({ channel, onSendMessage, onClose, selectedChat ,activeTab}) => {
  //const appId="71BB85F5-4AB3-4520-9681-B1A257FA20C5";
  const userId = useSelector((state) => state.auth)
  const token = userId.userInfo.doctor_login.sendbird_user_id
  const appId = SENDBIRD_INFO.appId
  //const token = "5b4e0e4d1d20763e85bc287e7fcfd2a52502b86f";
  return (
    <div className='w-full h-full oveflow-y-auto relative'>
      {/* <Link to={"/chat"} className='pt-4 cursor-pointer mb-8'><MdOutlineArrowCircleLeft size={30}/></Link> */}
      <div className='chat flex-col w-full h-full bottom-0  flex'>
        {token && (
          <SendBirdProvider
            appId={appId}
            userId={token}
            uikitOptions={{
                groupChannelList:{
                    enableTypingIndicator:true,
                }
            }}
          >
            <CustomDropZone channel={channel}>
              <ChatUI 
                activeTab={activeTab}
                close={onClose}
                channel={channel}
                currentUser={userId}
                selectedChat={selectedChat}
              />
            </CustomDropZone>
          </SendBirdProvider>
        )}
      </div>
    </div>
  )
}
export default Chat
