export const SENDBIRD_INFO = {
  appId:'18C5DFF5-E69F-4397-82DF-3DD9EC8BF18E', //prod,
  sendBirdToken:'f235fc53bd95780fd9f73ba812b1df980984ec2f' //prod
  // appId : 'FEEF3548-E6CD-4769-BEB2-99DB9A331095',//local
  // sendBirdToken:'f8f8fd8edf86c2c9c54d063a19f3b6343fa5ab38'//local
  };


// const one = {
//   name:"Hello Test",
//   id:"PA16"
// }  
// const two = {
//   name:"jeremy",
//   id:"PR1"
// }  
// const thre = {
//   name:"first users",
//   id:"PA10828"
// }  
// const four = {
//   name:"Anastasia Benson, D.O",
//   id:"PR11"
// }  
// const five = {
//   name:"Annette Marin",
//   id:"PR10"
// } 
//  const six = {
//   name:"mellisa",
//   id:"PR3"
// }  
// const seven ={
//   name:"nikolas",
//   id:"PA14"
// }
 