
import { useSelector } from "react-redux";
// import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import PrevChatPreview from "./prevChatPreview";
import { SENDBIRD_INFO } from "../../../../utils/helpers/constant";

const PrevChatPage = ({data}) => {
    // const store = useSendbirdStateContext();
    const userId = useSelector(state => state.auth);
    // const sdk = store?.stores.sdkStore.sdk;
    const token = userId.userInfo.doctor_login.sendbird_user_id;
    const appId = SENDBIRD_INFO.appId;
    return (
      <div className="w-full h-screen relative ">
      <div className="chat w-full h-full  flex relative pb-5">
      {/* <SendbirdProvider appId={appId} userId={token}> */}
      {userId && <PrevChatPreview data={data}/>}
      {/* </SendbirdProvider> */}
  </div>
  </div>
    );
};
export default PrevChatPage; 